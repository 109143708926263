<template>
    <a-card title="" :bordered="false">
        <div v-if="loading" class="init-loading">
            <a-spin size="large" tip="加载中，请稍后" />
        </div>
        <div v-if="article.id" class="article-wrap">
            <h3 class="article-wrap__title">{{article.title}}</h3>
            <div class="article-wrap__content" v-html="article.content"></div>
        </div>
        <a-empty v-if="error" class="data-empty" description="出错了，找不到文章数据">
            </a-empty>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            error: false,
            article: {}
        }
    },
    computed: {
    },
    created() {
        const id = this.$route.params.id
        this.$api.articleDetail({id}).then(res => {
            if(res.status) {
                this.article = res.data;
            }else{
                this.error = true;
            }
        }).finally(() => {
            this.loading = false;
        })
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
// 空数据
.data-empty {
    padding: 180px 0;
}

// 进入加载状态
.init-loading {
    text-align: center;
    padding: 100px 0;
    height: 320px;
}
// 文章详情
.article-wrap{
    &__title{
        font-size: 26px;
        text-align: center;
        color: #000;
    }
    &__content{
        padding:15px 0;
        font-size: 16px;
        a{color: #0000ff}
        a:hover{text-decoration: underline;}
        p {padding: 10px 0;}
        img{max-width: 100%; height: auto !important}
    }
}
</style>